import React, { Component } from "react"
import _ from "underscore"

import { StateContext } from "../components/common/provider"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Tags from "../components/utils/tags"
import Search from "../components/utils/search"
import StoreList from "../components/stores/list"
import Map from "../components/stores/map"
import { graphql } from "gatsby"

class StoresPage extends Component {
    static contextType = StateContext

    state = {
        viewportHeight: 320,
        activeSegment: null,
        searchResults: null,
        currentPosition: null
    }

    storeList = []
    searchIndex = []
    storeListRef = null

    constructor(props) {
        super(props);

        this.getStoreListRef = this.getStoreListRef.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.receivedPosition = this.receivedPosition.bind(this);

        this.mapRef = React.createRef();
    }

    windowResize() {
        this.setState({
            viewportHeight: window.innerHeight
        })
    }

    componentDidMount() {
        this.windowResize()
        window.addEventListener("resize", this.windowResize.bind(this))

        if (this.context.storeSegment) {
            this.setState({
                activeSegment: this.context.storeSegment
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.windowResize.bind(this))
    }

    segmentSelected = (segment) => {
        const self = this

        segment = segment && 'key' in segment ? segment.key : null
        
        this.context.setStoreSegment(segment)
        
        this.setState({ activeSegment: segment }, function() {
            self.search.updateIndex(this.searchIndex)
        })

        this.forceUpdate()
    }

    handleSearch = (results) => {
        if (results) {
            results = _.pluck(results, 'ref')
        }

        this.setState({ searchResults: results })
    }


    getStoreListRef(ref) {
        this.storeListRef = ref
    }

    getLocation() {
        this.storeListRef && this.storeListRef.getLocation();
    }

    receivedPosition(position) {
        const self = this

        this.setState({
            currentPosition: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }
        }, function() {
            if (self.mapRef)
                self.mapRef.current.mapResize()
        })
    }

    render() {
        const self = this

        const { data: {
            allWordpressWpStores,
            allWordpressWpSegments
        } } = this.props

        const tags = allWordpressWpSegments.edges.map(({ node }) => {
            return {
                key: node.wordpress_id,
                label: node.name
            }
        })

        self.searchIndex = []

        this.storeList = allWordpressWpStores.edges.filter(({ node }) => {
            let isListed = true

            const { activeSegment, searchResults } = self.state

            if (activeSegment) {
                isListed = node.segments.includes(activeSegment)
            }

            if (isListed) {
                self.searchIndex.push({
                    id: node.id,
                    title: node.title,
                    description: node.acf.description,
                    excerpt: node.acf.excerpt,
                    owner: node.acf.owner,
                    address: node.acf.address,
                    brands: _.pluck(node.brand_nodes, "name").join(" "),
                    products: _.pluck(node.product_nodes, "name").join(" "),
                    keywords: node.acf.keywords,
                })
            }

            if (isListed && searchResults) {
                return searchResults.includes(node.id)
            }

            return isListed
        })

        return (
            <Layout mainClassName="pb-0">
                <SEO title="Geschäfte" />
                <h1 className="screen-reader-text">Geschäfte</h1>

                <Map currentPosition={this.state.currentPosition} markers={this.storeList} style={{ height: this.state.viewportHeight / 2 }} ref={this.mapRef} />

                <div className="-mt-3">
                    <div className="relative select-none pt-px pb-24 rounded-t-xl bg-white min-h-screen">
                        <div className="container narrow">
                            <Search placeholder="Ort suchen" index={this.searchIndex} handleSearch={this.handleSearch} onRef={ref => (this.search = ref)} />
                        </div>
                        <div className="container narrow">
                            <Tags tags={tags} handleTagSelect={this.segmentSelected} activeTagKey={this.state.activeSegment} allowHtml={true} />
                        </div>
                        <div className="container narrow">
                            <StoreList stores={this.storeList} onGetLocation={this.getLocation} ref={this.getStoreListRef} onSuccess={this.receivedPosition} segment={this.state.activeSegment} />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default StoresPage

export const query = graphql`
    query StoresPageQuery {
        allWordpressWpStores(sort: {
            fields: [acf___featured, title],
            order: [DESC, ASC]
        }) {
            edges {
                node {
                    id
                    title
                    slug
                    segments
                    featured_media {
                        localFile {
                            childImageSharp {
                                fixed(width: 64, height: 64) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                    products
                    product_nodes {
                        name
                    }
                    brands
                    brand_nodes {
                        name
                    }
                    acf {
                        description
                        excerpt
                        address
                        owner
                        geo {
                            latitude
                            longitude
                        }
                        no_openings
                        opening_hours {
                            mon {
                                opened,
                                time {
                                    from
                                    to
                                }
                            }
                            tue {
                                opened,
                                time {
                                    from
                                    to
                                }
                            }
                            wed {
                                opened,
                                time {
                                    from
                                    to
                                }
                            }
                            thu {
                                opened,
                                time {
                                    from
                                    to
                                }
                            }
                            fri {
                                opened,
                                time {
                                    from
                                    to
                                }
                            }
                            sat {
                                opened,
                                time {
                                    from
                                    to
                                }
                            }
                            sun {
                                opened,
                                time {
                                    from
                                    to
                                }
                            }
                        }
                        keywords
                    }
                }
            }
        }
        allWordpressWpSegments(filter: {count: {gt: 0}}) {
            edges {
                node {
                    name
                    wordpress_id
                }
            }
        }
    }
`
