import React, { Component } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Avatar from "../utils/avatar"
import { LoadScriptNext, GoogleMap, Marker, InfoWindow } from "@react-google-maps/api"
import markerImage from "../../images/marker.png"
import currentPositionImage from "../../images/current-position.png"

const markerIcon = { url: markerImage, scaledSize: { width: 40, height: 40 } }
const currentPositionIcon = { url: currentPositionImage, scaledSize: { width: 48, height: 48 } }
let MARKER_INFO_WINDOW_OFFSET = null

class Map extends Component {
    bounds = []
    map = null

    state = {
        openInfoWindowMarkerId: null
    }

    constructor(props) {
        super(props);

        this.mapResize = this.mapResize.bind(this);
        this.mapLoad = this.mapLoad.bind(this);
        this.handleMarkerClick = this.handleMarkerClick.bind(this);
        this.handleMarkerClose = this.handleMarkerClose.bind(this);
    }

    mapResize = () => {
        if (!this.map)
            return false

        const bounds = new window.google.maps.LatLngBounds()

        this.bounds.map(position => {
            return bounds.extend(position)
        })

        this.map.fitBounds(bounds)
    }

    mapLoad = (map) => {
        this.map = map

        MARKER_INFO_WINDOW_OFFSET = new window.google.maps.Size(0, -40)

        this.mapResize()
        map.setOptions({
            zoomControlOptions: { position: window.google.maps.ControlPosition.TOP_RIGHT }
        })
    }

    handleMarkerClick = (markerId) => {
        const value = markerId === this.state.openInfoWindowMarkerId
            ? null
            : markerId

        this.setState({
            openInfoWindowMarkerId: value
        });
    }

    handleMarkerClose = () => {
        this.setState({
            openInfoWindowMarkerId: null
        });
    }

    render() {
        const { markers, currentPosition, ...other } = this.props
        this.bounds = []

        let _currentPosition

        if (currentPosition) {
            _currentPosition = {
                lat: Number.parseFloat(currentPosition.latitude),
                lng: Number.parseFloat(currentPosition.longitude)
            }
            this.bounds.push(_currentPosition)
        }

        return (
            <div id="map" {...other}>
                <LoadScriptNext googleMapsApiKey={process.env.GATSBY_GOOGLE_API_KEY || ""} language="de" region="DE">
                    <GoogleMap
                        preventGoogleFonts
                        mapContainerStyle={{ height: '100%' }}
                        center={{ lat: 48.551273, lng: 8.725793 }}
                        zoom={10}
                        options={{
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                        }}
                        onLoad={this.mapLoad}
                        onResize={this.mapResize}>
                        {markers.filter(({node}) => node.acf.geo.latitude && node.acf.geo.longitude).map(({node}) => {
                            const position = {
                                lat: Number.parseFloat(node.acf.geo.latitude),
                                lng: Number.parseFloat(node.acf.geo.longitude)
                            }
                            this.bounds.push(position)

                            return (
                                <React.Fragment key={"marker-" + node.id}>
                                    <Marker position={position} icon={markerIcon} onClick={() => this.handleMarkerClick(node.id)} />
                                    {this.state.openInfoWindowMarkerId === node.id &&
                                    <InfoWindow position={position} onCloseClick={() => this.handleMarkerClose()} options={{
                                        pixelOffset: MARKER_INFO_WINDOW_OFFSET
                                    }}>
                                        <div className="leading-snug pr-2 flex">
                                            <Avatar className="w-8 mr-2 mb-2 flex-none">
                                                {node.featured_media && node.featured_media.localFile &&
                                                    <Img
                                                        fixed={node.featured_media.localFile.childImageSharp.fixed}
                                                        alt={node.title}
                                                        />
                                                }
                                            </Avatar>
                                            <div className="my-1">
                                                <h3 className="my-0 text-sm">{node.title}</h3>
                                                <div className="mb-1">{node.acf.address}</div>
                                                <Link to={`/stores/${node.slug}`} className="text-primary font-bold mt-1 border-0 py-1">
                                                    Mehr
                                                </Link>
                                            </div>
                                        </div>
                                    </InfoWindow>
                                    }
                                </React.Fragment>
                            )
                        })}
                        {currentPosition &&
                            <Marker key="currentPosition" position={_currentPosition} icon={currentPositionIcon} />
                        }
                    </GoogleMap>
                </LoadScriptNext>
            </div>
        )
    }
}

export default Map
